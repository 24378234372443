// src/chunking.ts
var CHUNK_MAX_SIZE = 1e6;
var BATCH_SENTINEL = "y-pk-batch";
var TRACE_ENABLED = false;
var trace = (...args) => TRACE_ENABLED && console.log(...args);
var warnedAboutLargeMessage = false;
var sendChunked = (data, send) => {
  if (data.byteLength <= CHUNK_MAX_SIZE) {
    send(data);
    return;
  }
  if (!warnedAboutLargeMessage) {
    console.warn(
      "[y-partykit]",
      "The Y.js update size exceeds 1MB, which is the maximum size for an individual update. The update will be split into chunks. This is an experimental feature.",
      `Message size: ${(data.byteLength / 1e3 / 1e3).toFixed(1)}MB`
    );
    warnedAboutLargeMessage = true;
  }
  const id = (Date.now() + Math.random()).toString(36).substring(10);
  const chunks = Math.ceil(data.byteLength / CHUNK_MAX_SIZE);
  send(
    serializeBatchMarker({
      id,
      type: "start",
      size: data.byteLength,
      count: chunks
    })
  );
  let sentSize = 0;
  let sentChunks = 0;
  for (let i = 0; i < chunks; i++) {
    const chunk = data.slice(CHUNK_MAX_SIZE * i, CHUNK_MAX_SIZE * (i + 1));
    send(chunk);
    sentChunks += 1;
    sentSize += chunk.byteLength;
  }
  send(
    serializeBatchMarker({
      id,
      type: "end",
      size: sentSize,
      count: sentChunks
    })
  );
};
var handleChunked = (receive) => {
  let batch;
  let start;
  return (message) => {
    if (isBatchSentinel(message.data)) {
      const marker = parseBatchMarker(message.data);
      if (marker.type === "start") {
        batch = [];
        start = marker;
      }
      if (marker.type === "end") {
        if (batch) {
          assertEquality(start?.id, marker.id, "client id");
          assertEquality(start?.count, marker.count, "client counts");
          assertEquality(start?.size, marker.size, "client size");
          const size = batch.reduce(
            (sum, buffer) => sum + buffer.byteLength,
            0
          );
          const bytes = new Uint8Array(size);
          let bytesWritten = 0;
          for (const chunk of batch) {
            bytes.set(new Uint8Array(chunk), bytesWritten);
            bytesWritten += chunk.byteLength;
          }
          assertEquality(marker.count, batch.length, "received batch count");
          assertEquality(marker.size, bytesWritten, "client size");
          batch = void 0;
          start = void 0;
          receive(bytes);
        }
      }
    } else if (batch) {
      batch.push(message.data);
    } else {
      receive(new Uint8Array(message.data));
    }
  };
};
function assertEquality(expected, actual, label) {
  if (expected !== actual) {
    throw new Error(
      `Mismatching ${label}! Expected ${expected}, got ${actual}`
    );
  } else {
    trace(`Matching ${label}: ${expected}`);
  }
}
function isBatchSentinel(msg) {
  return typeof msg === "string" && msg.startsWith(BATCH_SENTINEL);
}
function serializeBatchMarker(batch) {
  return `${BATCH_SENTINEL}#${JSON.stringify(batch)}`;
}
function parseBatchMarker(msg) {
  const [sentinel, data] = msg.split("#", 2);
  if (sentinel !== BATCH_SENTINEL) {
    throw new Error("Unexpected batch marker: " + msg);
  }
  const batch = JSON.parse(data);
  if (batch.type !== "start" && batch.type !== "end") {
    throw new Error("Unexpected batch data: " + msg);
  }
  return batch;
}

export {
  sendChunked,
  handleChunked
};
